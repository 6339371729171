import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useSWR, { useFetch } from "@/lib/api";
import { toast } from "sonner";
import { CreateAccessRequestDialog } from "./create-access-request";
import { format } from "date-fns";

interface AccessRequest {
  id: string;
  resource: string;
  requester: string;
  status: string;

  requested_at: string;
  approvedAt?: Date;
  deniedAt?: Date;
  expiresAt?: Date;
}

function StatusBadge({ status }: { status: string }) {
  if (status === "pending") {
    return <Badge>Pending</Badge>;
  }
  if (status === "approved") {
    return <Badge variant="green">Approved</Badge>;
  }
  if (status === "denied") {
    return <Badge variant="destructive">Denied</Badge>;
  }
  return <Badge>Unknown</Badge>;
}
const AccessRequestsPage = () => {
  const fetch = useFetch();
  const { data, error, mutate } = useSWR<AccessRequest[]>("/accessrequests");

  if (error) return <div>Error loading access requests</div>;
  if (!data) return <div>Loading...</div>;

  const approveRequest = (id: string) => {
    const promise = fetch(`/accessrequests/${id}/approve`, {
      method: "PUT",
    });

    toast.promise(promise, {
      loading: "Approving request...",
      success: "Request approved",
      error: "Failed to approve request",
    });
  };

  const denyRequest = (id: string) => {
    const promise = fetch(`/accessrequests/${id}/deny`, {
      method: "PUT",
    });

    toast.promise(promise, {
      loading: "Denying request...",
      success: "Request denied",
      error: "Failed to deny request",
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-4 py-2">
        <h1>Access Requests</h1>
        <CreateAccessRequestDialog onSubmit={() => mutate()} />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Request ID</TableHead>
            <TableHead>Requester</TableHead>
            <TableHead>Resource</TableHead>
            <TableHead>Requested At</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="w-80">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((request) => (
            <TableRow key={request.id}>
              <TableCell>{request.id}</TableCell>
              <TableCell>{request.requester}</TableCell>
              <TableCell>{request.resource}</TableCell>
              <TableCell>
                {format(new Date(request.requested_at), "d MMM, HH:mm:ss")}
              </TableCell>
              <TableCell>
                <StatusBadge status={request.status} />
              </TableCell>
              <TableCell className="flex justify-between">
                <Button
                  variant="green"
                  className="w-1/2 mr-2"
                  onClick={() => approveRequest(request.id)}
                >
                  Approve
                </Button>
                <Button
                  variant="red"
                  className="w-1/2 ml-2"
                  onClick={() => denyRequest(request.id)}
                >
                  Deny
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AccessRequestsPage;
