// create acess request dialog
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import useSWR from "@/lib/api";
import { Identity } from "@/routes/identities/columns";
import {
  Form,
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormField,
  FormMessage,
} from "@/components/ui/form";
import { useForm, SubmitHandler } from "react-hook-form";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { ChevronsUpDown, Check } from "lucide-react";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { toast } from "sonner";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

import { forwardRef } from "react";
import { Textarea } from "@/components/ui/textarea";

import { useFetch } from "@/lib/api";

interface FormValues {
  // Define your form fields here
  requester: string;
  target: string;
  duration: string;
  reason: string;
}

interface CreateAccessRequestDialogProps {
  onSubmit?: SubmitHandler<FormValues>;
}

export function CreateAccessRequestDialog(
  props: CreateAccessRequestDialogProps
) {
  const form = useForm<FormValues>({
    defaultValues: {},
  });

  const [open, setOpen] = useState(false);

  const fetch = useFetch();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // Handle form submission
    console.log(data);

    const promise = fetch("/accessrequests", {
      method: "POST",
      body: JSON.stringify(data),
    }).then(() => {
      setOpen(false);
      if (props.onSubmit) {
        props.onSubmit(data);
      }
    });

    toast.promise(promise, {
      loading: "Creating access request...",
      success: "Access request created",
      error: (err) =>
        `Failed to create access request: ${err.body ?? err.message}`,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="p-2 rounded-md bg-primary text-primary-foreground shadow hover:bg-primary/90">
        Create New Access Request
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Create New Access Request</DialogTitle>
          <DialogDescription>Create a new access request.</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="requester"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Requester</FormLabel>
                  <FormControl>
                    <UserPicker
                      value={field.value}
                      setValue={(value) => form.setValue("requester", value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="target"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Target</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter target resource json"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="duration"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Duration</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter duration"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    The duration of the access request. For example: 1m, 1h30m,
                    1d
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Justification</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Enter justification" {...field} />
                  </FormControl>
                  <FormDescription>
                    Provide a justification for the access request.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">Submit</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

interface UserPickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  setValue: (value: string) => void;
}
const UserPicker = forwardRef<HTMLInputElement, UserPickerProps>(
  ({ value, setValue }, ref) => {
    const [open, setOpen] = useState(false);

    const {
      data: identities = [],
      error,
      isLoading,
    } = useSWR<Identity[]>(
      "/tenant/00000000-0000-0000-0000-000000000000/identities"
    );

    const identityDisplayName = (identity: Identity) => {
      return `${identity.full_name} (${identity.email})`;
    };

    let emptyMessage = "No users found";

    if (isLoading) {
      emptyMessage = "Loading...";
    }

    if (error) {
      emptyMessage = "Failed to load users";
    }

    const currentIdentity = identities.find((ident) => ident.id === value);
    return (
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="flex w-[300px] justify-between"
          >
            <p className="truncate" ref={ref}>
              {(currentIdentity && identityDisplayName(currentIdentity)) ||
                "Select user..."}
            </p>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[300px] p-0">
          <Command>
            <CommandInput placeholder="Search identities..." />
            <CommandList>
              <CommandEmpty>{emptyMessage}</CommandEmpty>
              <CommandGroup>
                {identities.map((identity) => (
                  <CommandItem
                    key={identity.email}
                    value={identity.id}
                    onSelect={(currentValue) => {
                      setValue(currentValue === value ? "" : currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === identity.id ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {identityDisplayName(identity)}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
