import useSWR from 'swr'
import { useAuth } from '@clerk/clerk-react';

const BASE_URL = 'https://api.permiss.io';

export default function useFetchSWR<T>(url: string) {
  const { getToken } = useAuth();

  if (!url.startsWith('http')) {
    url = BASE_URL + url;
  }
  const fetcher = async (input: RequestInfo, init?: RequestInit) => {
    return fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${await getToken()}`,
      },
    }).then((result) => {
      if (result.ok) {
        return result.json()
      }

      const responseError = {
        type: 'Error',
        statusText: result.statusText || 'Something went wrong',
        body: result.body || '',
        status: result.status || '',
      };

      let error = new Error();
      error = { ...error, ...responseError };
      return Promise.reject(error)
    })
  }

  return useSWR<T>(url, fetcher)
}

export function useFetch() {
  const { getToken } = useAuth()

  const authenticatedFetch = async <T>(url: string, init?: RequestInit): Promise<T> => {
    if (!url.startsWith('http')) {
      url = BASE_URL + url
    }
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${await getToken()}`,
      },
    }).then(async (result) => {
      if (result.ok) {
        return result.json()
      }

      const responseError = {
        type: 'Error',
        statusText: result.statusText || 'Something went wrong',
        body: await result.text() || '',
        status: result.status || '',
      };

      let error = new Error();
      error = { ...error, ...responseError };
      return Promise.reject(error)
    })
  }
  return authenticatedFetch
}