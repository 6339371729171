import { useState } from "react";
import AceEditor from "react-ace";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import useSWR, { useFetch } from "@/lib/api";
import EditableLabel from "@/components/editable-label";
import { useStickyState } from "@/lib/stickystate";
import { toast } from "sonner";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

interface PolicyListProps {
  policies: Policy[];
  onPolicySelect: (policy: Policy) => void;
  selectedPolicy: Policy | null;
}

function PolicyEvaluator({ policy }: { policy: Policy | null }) {
  const [inputParams, setInputParams] = useStickyState(
    "",
    `policy-input-${policy?.id}`
  );
  const [evaluationResult, setEvaluationResult] = useState<string>("");
  const fetch = useFetch();

  const handleEvaluatePolicy = async () => {
    const evaluatePolicyPromise = fetch(`/policies/${policy?.id}/simulate`, {
      method: "PUT",
      body: inputParams ?? "",
    });

    toast.promise(evaluatePolicyPromise, {
      loading: "Evaluating policy...",
      success: "Policy evaluated",
      error: "Failed to evaluate policy",
    });

    const resp = await evaluatePolicyPromise;
    setEvaluationResult(JSON.stringify(resp, null, 2));
  };
  return (
    <div className="flex space-x-4 ...">
      <div className="box-content w-1/2">
        <h3 className="font-semibold leading-none tracking-tight mb-4">
          Policy Input
        </h3>
        <AceEditor
          mode="json"
          value={inputParams}
          onChange={setInputParams}
          name="policy-editor"
          editorProps={{ $blockScrolling: true }}
          className="w-1/2"
          height="500px"
          width="100%"
        />
        <Button
          onClick={handleEvaluatePolicy}
          className="inline-block w-full mt-4"
          variant="default"
          disabled={!policy || !policy?.id}
        >
          Evaluate Policy
        </Button>
      </div>
      <div className="box-content w-1/2">
        <h3 className="font-semibold leading-none tracking-tight mb-4">
          Response
        </h3>
        <AceEditor
          mode="json"
          value={evaluationResult}
          name="policy-editor"
          editorProps={{ $blockScrolling: true }}
          className="w-1/2"
          height="500px"
          width="100%"
          readOnly
        />
      </div>
    </div>
  );
}

export function PolicyList({
  policies,
  onPolicySelect,
  selectedPolicy,
}: PolicyListProps) {
  return (
    <ScrollArea className="h-72 w-48 rounded-md border">
      <div className="p-4">
        <h4 className="mb-4 text-sm font-medium leading-none">Policies</h4>
        {policies.map((policy) => (
          <>
            <div
              key={policy.id}
              className={
                selectedPolicy && selectedPolicy.id === policy.id
                  ? "bg-blue-500 text-white p-2 rounded-md cursor-pointer"
                  : "p-2 rounded-md cursor-pointer"
              }
              onClick={() => onPolicySelect(policy)}
            >
              {policy.name}
            </div>
            <Separator className="my-2" />
          </>
        ))}
      </div>
    </ScrollArea>
  );
}

interface Policy {
  id?: string;
  name?: string;
  policy: string;
}

const PolicySimulator = () => {
  const {
    data: policies,
    error,
    isLoading,
    mutate,
  } = useSWR<Policy[]>("/policies");
  const fetch = useFetch();
  const [editorValue, setEditorValue] = useState<Policy | null>(null);
  const evaluationResult = false;

  const handleSavePolicy = async () => {
    if (!editorValue) {
      return;
    }
    if (!editorValue.id) {
      const newPolicyPromise = fetch<Policy>("/policies", {
        method: "POST",
        body: JSON.stringify({
          name: "New Policy",
          policy: editorValue.policy,
        }),
      });
      toast.promise(newPolicyPromise, {
        loading: "Saving policy...",
        success: "Policy saved",
        error: "Failed to save policy",
      });

      const newPolicy = await newPolicyPromise;
      setEditorValue({ ...editorValue, id: newPolicy.id });
      mutate();
      return;
    }

    const updatePolicyPromise = fetch(`/policies/${editorValue?.id}`, {
      method: "PUT",
      body: JSON.stringify(editorValue),
    });

    toast.promise(updatePolicyPromise, {
      loading: "Saving policy...",
      success: "Policy saved",
      error: "Failed to save policy",
    });

    mutate();
  };

  const handleLoadPolicy = (policy: Policy) => {
    setEditorValue(policy);
  };

  const saveEditorValue = (policy: string) => {
    if (!policy) {
      return;
    }
    setEditorValue({ ...editorValue, policy });
  };
  const handleCreatePolicy = () => {
    const newPolicy = {
      name: "New Policy",
      policy: "",
    };
    policies?.push(newPolicy);
    setEditorValue(newPolicy);
  };

  const renamePolicy = (name: string) => {
    if (!editorValue) {
      return;
    }
    setEditorValue({ ...editorValue, name });
    policies?.forEach((policy: Policy) => {
      if (policy.id === editorValue.id) {
        policy.name = name;
      }
    });
  };
  if (error) {
    return <div>Error loading policies</div>;
  }

  return (
    <div className="flex h-screen">
      <div className="w-1/3 p-4">
        <Card>
          <CardHeader>
            <CardTitle>Saved Policies</CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading && <div>Loading...</div>}
            {policies && (
              <PolicyList
                policies={policies}
                onPolicySelect={handleLoadPolicy}
                selectedPolicy={editorValue}
              />
            )}
            <Button onClick={handleSavePolicy}>Save Policy</Button>
            <Button onClick={handleCreatePolicy} variant="outline">
              Create Policy
            </Button>
          </CardContent>
        </Card>
      </div>
      <div className="w-2/3 p-4">
        <Card>
          <CardHeader>
            <CardTitle>Policy Editor</CardTitle>
            <div className="flex items-center space-x-2">
              <EditableLabel
                initialValue={editorValue?.name || "New Policy"}
                className="inline-block w-2/3"
                onChange={renamePolicy}
              />
            </div>
          </CardHeader>
          <CardContent>
            <AceEditor
              mode="rego"
              theme="github"
              value={editorValue?.policy}
              onChange={(newValue) => saveEditorValue(newValue)}
              name="policy-editor"
              editorProps={{ $blockScrolling: true }}
              className="mb-4"
              showPrintMargin={false}
              height="500px"
              width="100%"
            />
            {evaluationResult && (
              <div>
                <h2>Evaluation Result</h2>
                <pre>{JSON.stringify(evaluationResult, null, 2)}</pre>
              </div>
            )}
            <Separator orientation="horizontal" className="my-4" />
            <PolicyEvaluator policy={editorValue} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default PolicySimulator;
